import React from 'react';
import './About.css';
import { images } from '../../Constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import emailjs from '@emailjs/browser';
import { useState } from 'react';

const About = () => {
    const [nom, setNom] = useState("");
    const [tel, setTel] = useState("");
    const [ser, setSer] = useState("");
  const sendEmail = (e)  => {
    e.preventDefault();
    emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_PUBLIC_KEY);
    setNom("");
    setTel("");
    setSer("");
    alert("votre message a été envoyé, un agent SOS Hygiene Controle vous contactera le plus tôt possible pour établir votre devis");
  };
  return (
    <section className="about" id="about">
        <h3>Devis gratuit immediat : prix bas garantis</h3>
        <form className="form" onSubmit={sendEmail}>
            <div className="input-container ic2">
                <select value={ser} onChange={e => setSer(e.target.value)} name="services" id="service" className="input" required>
                    <option value="">Selectionner un service</option>  
                    <option value="Punaises de lit">Punaises de lit</option>
                    <option value="Insectes">Insectes</option>
                    <option value="Rats et souris">Rats et souris</option>
                    <option value="Désinfection">Désinfection</option>
                    <option value="Pigeons">Pigeons</option>
                    <option value="Autres nuisibles">Autres nuisibles</option>
                </select>
                <div className="cut"></div>
                <label htmlFor="services" className="placeholder">Services</label>
            </div>
            <div className="input-container ic2">
                <input type='text' value={nom} onChange={e => setNom(e.target.value)} name='nom' id='nom' placeholder=' ' className='input' required/>
                <div className="cut"></div>
                <label htmlFor="nom" className="placeholder">Nom</label>
            </div>
            <div className="input-container ic2">
                <input type='text' value={tel} onChange={e => setTel(e.target.value)} name='telephone' id='telephone' placeholder=' ' className='input' required/>
                <div className="cut cut-short"></div>
                <label htmlFor="telephone" className="placeholder">Téléphone</label>
            </div>
            <button type="text" className="submit">Devis immédiat</button>
            
        </form>

        <div className="container">
            <div className="row align-items-center">
                <div className="col-md-4 image">
                    <img src={images.LogoControl}  alt="Aboutimg"/>
                </div>
                <div className="col-md-8 content">
                    <span>A propos de SOS Hygiene Controle</span>
                    <h3>Dites adieu aux nuisibles, dites bonjour à la tranquillité d'esprit !</h3>
                    <p>Nous fournissons des services de traitement contre les termites, les punaises de lit, les cafards, les rongeurs, les moustiques, les frelons, les frelons asiatiques, les pigeons, les guêpes, les abeilles, les mouches, les insectes xylophages et les araignées, etc.</p>
                    <p><span><FontAwesomeIcon  icon={faCheckCircle} color='green'/> </span>Nous croyons toujours en la prestation d'un service de qualité avec des normes élevées de satisfaction de la clientèle.</p>
                    <p><span><FontAwesomeIcon  icon={faCheckCircle} color='green'/> </span>Nous disposons également d'une équipe de techniciens qualifiés et dévoués.</p>
                    <p><span><FontAwesomeIcon  icon={faCheckCircle} color='green'/> </span>Nous utilisons des produits et des techniques innovants pour résoudre différents problèmes de nuisibles et répondre aux besoins de nos clients.</p>                    
                    <a href="https://wa.me/33763060101" target='blank' className="link-btn">Nous Contacter<span>--</span><WhatsAppIcon sx={{ color : 'green', fontSize: 50 }}/></a>
                </div>
            </div>
        </div>
    </section>
  )
}
export default About