import { images } from '../../Constants';
export const ServicesObject = [
    {
        id : 1,
        logo : images.PunaiseLogo,
        image : images.Punaise,
        title : "Punaises de lit",
        description : "SOS Hygiene Controle dispose de techniciens spécialisés dans la lutte contre les parasites, y compris les punaises de lit. Ils sont formés pour évaluer la situation et proposer des solutions efficaces pour éliminer ces parasites. SOS Hygiene Controle propose une intervention en urgence pour aider à éliminer les punaises de lit dans les locaux, et ses techniciens sont équipés des outils et des produits nécessaires pour résoudre le problème de manière efficace. Ils peuvent également fournir des conseils pour prévenir la réapparition des punaises de lit à l'avenir.",
    },
    {
        id : 2,
        logo : images.CafardLogo,
        image : images.Cafard,
        title : "Insectes",
        description : "SOS Hygiene Controle propose des services de lutte contre les insectes qui prennent en compte l'impact sur l'environnement, l'écologie et l'écosystème. Selon le degré d'infestation et le type d'insecte, différentes méthodes peuvent être proposées, y compris des méthodes naturelles qui minimisent l'utilisation de produits chimiques. Les techniciens de SOS Hygiene Controle peuvent recommander un traitement chimique, l'utilisation de gels ou d'autres solutions pour éliminer les insectes et empêcher leur retour, tout en veillant à minimiser l'impact environnemental.",
    },
    {
        id : 3,
        logo : images.RatLogo,
        image : images.Rat,
        title : "Rats et souris",
        description : "Il est important de traiter rapidement les problèmes de rongeurs dans un bâtiment car ils peuvent causer des dommages structurels, propager des maladies et endommager les biens. Les techniciens spécialisés en dératisation de SOS Hygiene Controle peuvent intervenir en urgence pour résoudre ce problème. Leur expertise leur permet de mettre en place des solutions efficaces pour éliminer les rongeurs présents dans les locaux et prévenir leur retour.",
    },
    {
        id : 4,
        logo : images.DesinLogo,
        image : images.Desinfection,
        title : "Désinfection",
        description : "SOS Hygiene Controle propose des services de désinfection pour éliminer les micro-organismes tels que les germes, les bactéries et les champignons présents dans les locaux. Ces services sont importants pour maintenir une bonne hygiène et prévenir la propagation de maladies et d'infections. Les produits et techniques de nettoyage utilisés par notre société sont efficaces pour éradiquer ces micro-organismes et assurer un environnement sain et sûr.",
    },
    {
        id : 5,
        logo : images.DesinLogo,
        image : images.Pigeons,
        title : "Pigeons",
        description : "SOS Hygiene Controle propose des solutions de lutte contre les pigeons à Paris et en Ile-de-France pour prévenir leur nidification sur les bâtiments, immeubles et monuments. Les pigeons peuvent causer des problèmes esthétiques, environnementaux et sanitaires, et notre société utilise des méthodes respectueuses de l'environnement pour éloigner les pigeons et prévenir leur retour.",
    },
    {
        id : 6,
        logo : images.TermiteLogo,
        image : images.Termite,
        title : "Autres nuisibles",
        description : "SOS Hygiene Controle propose des solutions de lutte contre une grande variété d'espèces nuisibles, telles que les mites, les fourmis, les chenilles processionnaires, etc. Ses techniciens spécialisés peuvent identifier les nuisibles et proposer des solutions adaptées pour les éliminer de manière sûre et respectueuse de l'environnement.",
    }
]